<template>
  <div class="mx-2 my-2 archived-card">
    <div v-if="state.archivedThreadsList.value.length !== 0">
      <v-card
        v-for="dt in state.archivedThreadsList.value"
        :key="dt.id"
        outlined
        class="chat-group d-flex align-start px-2 py-2 mb-2"
      >
        <div class="chat-avatar">
          <router-link
            class="d-flex"
            :to="{ name: 'user-detail', params: { id: dt.user.id } }"
          >
            <v-avatar
              size="38"
              color="primary"
            >
              <span class="white--text">{{ avatarText(dt.user.name) }}</span>
            </v-avatar>
          </router-link>
        </div>
        <div class="flex-column ml-4 mb-4 html-card">
          <div class="d-flex">
            <div class="d-flex flex-grow-1">
              <router-link
                class="mr-1"
                :to="{ name: 'user-detail', params: { id: dt.user.id} }"
              >
                <p class="text-caption">
                  {{ dt.user.name }}
                </p>
              </router-link>
              <span class="text-caption">| {{ dateFormat(dt.created_at) }}</span>
            </div>
            <div class="mr-2 flex-row">
              <v-btn
                class="menu-setting"
                elevation="1"
                x-small
                plain
                @click="unArchived(dt.id)"
              >
                <strong><em>Pulihkan</em></strong>
              </v-btn>
            </div>
          </div>
          <div class="d-flex">
            <div class="pin-width">
              <v-card
                class="py-3 px-4 elevation-1"
              >
                <div class="d-flex">
                  <div
                    class="flex-grow-1"
                  >
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                      class="content"
                      v-html="dt.html_comment"
                    />
                    <!-- eslint enable -->
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <v-card
      v-else
      class="text-center px-6 py-6"
    >
      <v-icon
        class="mb-2"
        size="64px"
      >
        {{ icons.mdiArchiveAlert }}
      </v-icon>
      <div class="text-h6">
        No Archived Threads
      </div>
      <div class="text-body-2">
        Archived threads will appear here.
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mdiArchiveAlert } from '@mdi/js'
import { avatarText } from '@/@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { unarchiveThread } from '@/graphql/mutations'
import { createFieldMapper } from 'vuex-use-fields'
import dateFormat from '@/utils/dateFormat'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  setup(props, { emit }) {
    const state = {
      ...useFieldTeam(['activeChannel', 'archivedThreadsList']),
    }

    const unArchived = id => {
      apolloClient.mutate({
        mutation: unarchiveThread,
        variables: {
          thread_id: id,
        },
      }).then(() => {
        state.archivedThreadsList.value = state.archivedThreadsList.value.filter(res => res.id !== id)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil memulihkan thread!',
        })
        emit('success')
      })
    }

    return {
      state,
      avatarText,
      unArchived,
      dateFormat,
      icons: {
        mdiArchiveAlert,
      },
    }
  },
}
</script>

<style lang="scss">
.archived-card {
  min-width: 320px;
}
</style>
