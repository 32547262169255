<template>
  <div
    class="chat-left-sidebar h-full"
  >
    <div class="top-fixed w-full">
      <div
        class="d-flex align-center h-full"
        style="padding: 15px 16px"
      >
        <input-icon-toggle
          :icon="icons.mdiMagnify"
          placeholder="Cari channel"
          class="me-auto"
          direction="right"
          icon-size="24px"
          @input="
            loadingSearch = true
            searchChannel($event)
          "
          @close="currentTab = 0"
        />
        <v-menu
          :close-on-content-click="false"
          max-width="290px"
          min-width="290px"
          offset-y
          left
          nudge-right="32"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="me-2"
              size="24px"
              v-bind="attrs"
              v-on="on"
            >
              {{ icons.mdiFilterVariant }}
            </v-icon>
          </template>

          <v-card class="pa-4">
            <span class="text-subtitle-1 font-weight-bold">Filter</span>
            <v-divider class="my-4" />
            <div class="mb-4">
              <v-select
                v-model="state.channelFilter.value.sort"
                placeholder="Sortir Berdasarkan"
                :items="sortOptions"
                item-text="label"
                return-object
                outlined
                dense
                hide-details
              />
            </div>
            <v-btn
              color="primary"
              block
              @click="$emit('refetch')"
            >
              Filter
            </v-btn>
          </v-card>
        </v-menu>
        <v-btn
          icon
          @click="$refs.channelForm.show(teamId)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
        <v-icon
          class="d-inline-flex d-md-none ms-2"
          size="26px"
          @click="$emit('close-left-sidebar')"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-divider />
      <div
        v-if="!reveal"
        class="py-4 px-6 cursor-pointer"
        @click="getArchivedChannel(); reveal = true"
      >
        <div class="d-flex">
          <v-icon>{{ icons.mdiArchive }}</v-icon>
          <span class="ml-4 text-subtitle-2 font-weight-bold">Archived</span>
        </div>
      </div>
      <div
        v-else
        class="py-4 px-6 cursor-pointer"
        @click="$emit('refetch'); reveal = false"
      >
        <div class="d-flex">
          <v-icon>{{ icons.mdiClose }}</v-icon>
          <span class="ml-4 text-subtitle-2 font-weight-bold">Back</span>
        </div>
      </div>
    </div>
    <div
      class="ps-channel"
    >
      <v-tabs-items
        v-model="currentTab"
        v-scroll.self="onScrollChannel"
        class="h-full channel-scroll pb-3 px-3"
      >
        <v-tab-item>
          <div class="mt-2" />
          <div v-if="state.loadingChannels.value">
            <v-skeleton-loader
              v-for="i in 2"
              :key="i"
              type="list-item-avatar-two-line"
              class="p-0"
            />
          </div>
          <channel-card
            v-for="cnl in state.channelList.value"
            v-else
            :key="`channel-${cnl.id}`"
            :team="teamId"
            :data="cnl"
            :is-active="isChannelActive(cnl.id)"
            :class="{ 'bg-gradient-primary ': isChannelActive(cnl.id) }"
            @update="$refs.channelForm.update(cnl)"
            @delete="$emit('refetch')"
            @success="$emit('refetch')"
            @click="isChannelActive(cnl.id) ? null : $emit('open-channel', cnl)"
          />
          <div
            class="d-flex my-2"
          >
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              class="mx-auto"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <v-expand-transition>
      <div
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal ps-channel"
      >
        <v-tabs-items
          v-model="currentTab"
          v-scroll.self="listenArchivedChannelMore"
          class="h-full channel-scroll pb-3 px-3"
        >
          <v-tab-item>
            <div class="mt-2" />
            <div v-if="state.loadingChannels.value">
              <v-skeleton-loader
                v-for="i in 2"
                :key="i"
                type="list-item-avatar-two-line"
                class="p-0"
              />
            </div>
            <channel-card
              v-for="channel in state.archivedChannel.value"
              v-else
              :key="`channel-${channel.id}`"
              :team="teamId"
              :data="channel"
              :is-active="isChannelActive(channel.id)"
              :class="{ 'bg-gradient-primary ': isChannelActive(channel.id) }"
              @update="$refs.channelForm.update(channel)"
              @click="isChannelActive(channel.id) ? null : $emit('open-channel', channel)"
            />
            <div
              class="d-flex my-2"
            >
              <v-progress-circular
                v-if="loadingChannels"
                indeterminate
                color="primary"
                class="mx-auto"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-expand-transition>

    <channel-form ref="channelForm" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify, mdiPlus, mdiClose, mdiFilterVariant, mdiArchive,
} from '@mdi/js'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import ChannelCard from './components/ChannelCard.vue'
import ChannelForm from './components/ChannelForm.vue'
import { createFieldMapper } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'
import { channels } from '@/graphql/queries'
import { useDebounceFn } from '@vueuse/core'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    InputIconToggle,
    ChannelCard,
    ChannelForm,
  },
  props: {
    teamId: {
      type: [String, Number],
      default: null,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const loadingSearch = ref(false)
    const loadingChannels = ref(false)
    const currentTab = ref(0)
    const reveal = ref(false)
    const state = {
      ...useFieldTeam(['loadingChannels', 'channelList', 'channelFilter', 'activeChannel', 'channelFilter', 'archivedChannel']),
    }

    const sortOptions = ref([
      {
        label: 'Nama Channel A-Z',
        field: 'name',
        type: 'ASC',
      },
      {
        label: 'Nama Channel Z-A',
        field: 'name',
        type: 'DESC',
      },
      {
        label: 'Aktifitas Terbaru',
        field: 'last_activity_at',
        type: 'ASC',
      },
      {
        label: 'Aktifitas Terlama',
        field: 'last_activity_at',
        type: 'DESC',
      },
    ])

    const isChannelActive = channelId => {
      if (!state.activeChannel.value) return false

      return state.activeChannel.value.channel.id === channelId
    }

    const searchChannel = useDebounceFn(data => {
      const filter = state.channelFilter.value
      loadingSearch.value = true
      currentTab.value = 0

      apolloClient.query({
        query: channels,
        variables: {
          team_id: +props.teamId,
          pagination: {
            limit: 20,
            offset: filter.channelOffset,
          },
          filter: {
            sortType: filter.sort.field,
            sort: filter.sort.type,
          },
          search: data,
          is_archived: data ? null : state.channelFilter.value.isArchived,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.channelList.value = result.data.channels
        loadingSearch.value = false
      })
    }, 1000)

    const getArchivedChannel = (fetchMore = false, loading = true, success = () => {}) => {
      const filter = state.channelFilter.value
      if (loading) state.loadingChannels.value = true
      apolloClient.query({
        query: channels,
        variables: {
          team_id: +props.teamId,
          pagination: {
            limit: 20,
            offset: filter.channelOffset,
          },
          filter: {
            sortType: filter.sort.field,
            sort: filter.sort.type,
          },
          is_archived: true,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        if (fetchMore) {
          state.archivedChannel.value = [...state.archivedChannel.value, ...result.data.channels]
        } else {
          state.archivedChannel.value = result.data.channels
        }
        state.loadingChannels.value = false
        success()
      })
    }

    const onScrollChannel = data => {
      emit('onScrollChannel', data)
    }

    const listenArchivedChannelMore = useDebounceFn(async data => {
      if ((data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 100)) {
        state.channelFilter.value.channelOffset += 20
        loadingChannels.value = true
        await getArchivedChannel(true, false, () => {
          loadingChannels.value = false
        })
      }
    }, 1000)

    return {
      currentTab,
      state,
      isChannelActive,
      searchChannel,
      onScrollChannel,
      getArchivedChannel,
      listenArchivedChannelMore,
      loadingSearch,
      loadingChannels,
      sortOptions,
      reveal,
      icons: {
        mdiMagnify,
        mdiPlus,
        mdiClose,
        mdiFilterVariant,
        mdiArchive,
      },
    }
  },
}
</script>

<style lang="scss">
// @import '~@core/preset/preset/apps/chat.scss';
@import '~@core/preset/preset/mixins.scss';

.top-fixed {
  position: absolute;
  top: 0;
  z-index: 2;
}

.ps-channel {
  margin-top: 130px;
  height: calc(100% - 130px) !important;
}

.channel-scroll {
  @include style-scroll-bar();
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.v-card--reveal {
  bottom: 0;
  // opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
