<template>
  <div
    id="threadList"
    v-scroll:#threadList="listenScrollFetchMore"
    class="ps-thread h-full chat-log pa-5"
  >
    <div
      v-if="loading"
      class="d-flex align-center justify-center mt-4 h-full"
    >
      <v-progress-circular
        indeterminate
        size="48"
        color="primary"
      />
    </div>
    <div
      v-else
    >
      <div
        v-if="data.thread.length === 0"
        class="d-flex align-center justify-content-center h-full flex-grow-1 flex-column"
      >
        <v-avatar
          size="109"
          class="elevation-3 mb-6 bg-card"
        >
          <v-icon
            size="50"
            class="rounded-0 text--primary"
          >
            {{ icons.mdiCommentQuestionOutline }}
          </v-icon>
        </v-avatar>
        <p class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card">
          Belum ada thread di channel ini
        </p>
      </div>
      <div v-else>
        <div class="d-flex my-2">
          <v-progress-circular
            v-if="loadingFetchMore"
            indeterminate
            color="primary"
            class="mx-auto"
          />
        </div>
        <div
          v-bind="data"
          class="d-flex flex-column mb-4"
        >
          <!-- Thread -->
          <div
            v-for="(th, index) in data.thread"
            :key="th.id"
            class="chat-group d-flex align-start"
          >
            <div class="chat-avatar ms-2">
              <router-link
                class="d-flex"
                :to="{ name: 'user-detail', params: { id: th.user.id } }"
              >
                <v-avatar
                  size="38"
                  color="primary"
                >
                  <span class="white--text">{{ avatarText(th.user.name) }}</span>
                </v-avatar>
              </router-link>
            </div>
            <div
              :id="`threadContent-${th.id}`"
              class="flex-column ml-4 mb-4"
            >
              <div class="d-flex">
                <router-link
                  class="mr-1"
                  :to="{ name: 'user-detail', params: { id: th.user.id} }"
                >
                  <p class="text-caption">
                    {{ th.user.name }}
                  </p>
                </router-link>
                <span class="text-caption">| {{ dateFormat(th.created_at) }}</span>
              </div>
              <div class="d-flex">
                <div class="full-width">
                  <v-card
                    class="py-3 px-4 elevation-1"
                  >
                    <div class="d-flex html-card">
                      <div
                        :id="`content-${th.id}`"
                        class="flex-grow-1"
                        :class="th.showMore ? 'thread-content-all' : 'thread-content'"
                      >
                        <!-- eslint-disable vue/no-v-html -->
                        <div
                          class="content"
                          v-html="th.html_comment"
                        />
                      <!-- eslint enable -->
                      </div>
                      <div class="ml-2 flex-row">
                        <v-menu
                          bottom
                          offset-y
                          left
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="menu-setting"
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                size="18px"
                              >
                                {{ icons.mdiDotsVertical }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-list
                            nav
                          >
                            <v-list-item
                              v-show="!th.is_pinned"
                              @click="setPin('pin', th.id, index)"
                            >
                              <v-icon
                                small
                              >
                                {{ icons.mdiPin }}
                              </v-icon>
                              <span class="ml-4 text-subtitle-2">Pin</span>
                            </v-list-item>
                            <v-list-item
                              v-show="th.is_pinned"
                              @click="setPin('unpin', th.id, index)"
                            >
                              <v-icon
                                small
                              >
                                {{ icons.mdiPinOff }}
                              </v-icon>
                              <span class="ml-4 text-subtitle-2">Unpin</span>
                            </v-list-item>
                            <v-list-item
                              v-show="th.archived_at === null"
                              @click="archived(th.id, index)"
                            >
                              <v-icon
                                small
                              >
                                {{ icons.mdiArchiveArrowDown }}
                              </v-icon>
                              <span class="ml-4 text-subtitle-2">Archive</span>
                            </v-list-item>
                            <div v-if="th.user.id === store.getters.getUserData.id">
                              <v-divider />
                              <v-list-item @click="$refs.threadEdit.update(th)">
                                <v-icon
                                  small
                                >
                                  {{ icons.mdiSquareEditOutline }}
                                </v-icon>
                                <span class="ml-4 text-subtitle-2">Edit</span>
                              </v-list-item>
                              <v-list-item @click="hapusThread(th.id, index)">
                                <v-icon
                                  small
                                >
                                  {{ icons.mdiDelete }}
                                </v-icon>
                                <span class="ml-4 text-subtitle-2">Hapus</span>
                              </v-list-item>
                            </div>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    <div
                      v-if="th.html_comment.length > 120"
                    >
                      <v-divider class="mt-2 mb-2" />
                      <div
                        v-if="!th.showMore"
                        :id="`showThread-${th.id}`"
                        :ref="`showThread-${th.id}`"
                        class="text-caption cursor-pointer text-right"
                        @click="showMore('thread', th.id)"
                      >
                        Show more
                      </div>
                      <div
                        v-if="th.showMore"
                        class="text-caption cursor-pointer text-right"
                        @click="showLess('thread', th.id)"
                      >
                        Show less
                      </div>
                    </div>
                  </v-card>

                  <!-- Reply Thread -->
                  <div
                    class="chat-content py-2 px-4 elevation-1 justify-center mt-1"
                  >
                    <div v-if="th.posts.length > 0">
                      <div
                        v-if="state.activeChannel.value.threads.thread[index].remaining_post > 0"
                        class="cursor-pointer text-caption"
                        @click="showRemaining(th.id, index)"
                      >
                        Show {{ state.activeChannel.value.threads.thread[index].remaining_post }} replies
                        <v-divider class="mt-2 mb-2" />
                      </div>
                      <!-- Reply -->
                      <div
                        v-for="post in data.posts[index]"
                        :key="post.id"
                        class="mb-2"
                      >
                        <div class="d-flex pt-2 mr-2 align-center">
                          <div
                            class="text-caption cursor-pointer"
                            @click="$router.push({ name: 'user-detail', params: { id: post.user.id} })"
                          >
                            <v-avatar
                              size="32"
                              color="v-avatar-light-bg"
                              class="cursor-pointer"
                              @click="$router.push({ name: 'user-detail', params: { id: post.user.id} })"
                            >
                              <span>{{ avatarText(post.user.name) }}</span>
                            </v-avatar>
                            <span class="ml-2 mr-2 font-weight-bold">{{ post.user.name }}</span>
                          </div> | <span class="ml-1 text-caption">{{ dateFormat(post.created_at) }}</span>
                        </div>
                        <v-card class="d-flex flex-column mt-1 ml-4 pl-4 mr-4">
                          <div class="d-flex justify-space-between mt-2 html-card mr-2">
                            <div
                              :class="post.showMore ? 'reply-content-all' : 'reply-content'"
                            >
                              <!-- eslint-disable vue/no-v-html -->
                              <div
                                class="content"
                                v-html="post.html_comment"
                              />
                            <!-- eslint enable -->
                            </div>
                            <div
                              v-if="post.user.id === store.getters.getUserData.id"
                              class="ml-2 flex-row"
                            >
                              <v-menu
                                bottom
                                offset-y
                                left
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    class="menu-setting"
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon
                                      size="18px"
                                    >
                                      {{ icons.mdiDotsVertical }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <v-list
                                  nav
                                >
                                  <v-list-item @click="$refs.replyForm.update('post', post, index, th.id)">
                                    <v-icon
                                      small
                                    >
                                      {{ icons.mdiSquareEditOutline }}
                                    </v-icon>
                                    <span class="ml-4 text-subtitle-2">Edit</span>
                                  </v-list-item>
                                  <v-list-item @click="hapusPost('post', post.id, index)">
                                    <v-icon
                                      small
                                    >
                                      {{ icons.mdiDelete }}
                                    </v-icon>
                                    <span class="ml-4 text-subtitle-2">Hapus</span>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </div>
                          </div>
                          <div v-if="post.html_comment.length > 100">
                            <v-divider class="mt-2 mb-2 mr-4" />
                            <div
                              v-if="!post.showMore"
                              class="text-caption cursor-pointer mb-2 mr-4 text-right"
                              @click="showMore('reply', post.id, index)"
                            >
                              Show more
                            </div>
                            <div
                              v-if="post.showMore"
                              class="text-caption cursor-pointer mb-2 mr-4 text-right"
                              @click="showLess('reply', post.id, index)"
                            >
                              Show less
                            </div>
                          </div>
                        </v-card>
                        <v-divider class="mt-2 mb-2" />
                      </div>
                      <!-- Last Reply -->
                      <div class="d-flex pt-2 mr-2 align-center">
                        <div
                          class="text-caption cursor-pointer"
                          @click="$router.push({ name: 'user-detail', params: { id: data.last_post[index].user.id } })"
                        >
                          <v-avatar
                            size="32"
                            color="v-avatar-light-bg"
                            class="cursor-pointer"
                            @click="$router.push({ name: 'user-detail', params: { id: data.last_post[index].user.id } })"
                          >
                            <span>{{ avatarText(data.last_post[index].user.name) }}</span>
                          </v-avatar>
                          <span class="ml-2 mr-2 font-weight-bold">{{ data.last_post[index].user.name }}</span>
                        </div> | <span class="ml-1 text-caption">{{ dateFormat(data.last_post[index].created_at) }}</span>
                      </div>
                      <v-card class="d-flex flex-column mt-1 ml-4 pl-4 mr-4">
                        <div
                          class="d-flex mt-2 mb-2 html-card mr-2"
                        >
                          <div :class="data.last_post[index].showMore ? 'reply-content-all' : 'reply-content'">
                            <div
                              class="content"
                              v-html="data.last_post[index].html_comment"
                            />
                          </div>
                          <div
                            v-if="data.last_post[index].user.id === store.getters.getUserData.id"
                            class="ml-2 flex-row"
                          >
                            <v-menu
                              bottom
                              offset-y
                              left
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="menu-setting"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon
                                    size="18px"
                                  >
                                    {{ icons.mdiDotsVertical }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-list
                                nav
                              >
                                <v-list-item @click="$refs.replyForm.update('last', data.last_post[index], index, th.id)">
                                  <v-icon
                                    small
                                  >
                                    {{ icons.mdiSquareEditOutline }}
                                  </v-icon>
                                  <span class="ml-4 text-subtitle-2">Edit</span>
                                </v-list-item>
                                <v-list-item @click="hapusPost('last', data.last_post[index].id, index)">
                                  <v-icon
                                    small
                                  >
                                    {{ icons.mdiDelete }}
                                  </v-icon>
                                  <span class="ml-4 text-subtitle-2">Hapus</span>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </div>
                        <div v-if="data.last_post[index].html_comment.length > 100">
                          <v-divider class="mb-2 mr-4" />
                          <div
                            v-if="!data.last_post[index].showMore"
                            class="text-caption cursor-pointer mb-2 mr-4 text-right"
                            @click="showMore('last-reply', data.last_post[index].id, index)"
                          >
                            Show more
                          </div>
                          <div
                            v-if="data.last_post[index].showMore"
                            class="text-caption cursor-pointer mb-2 mr-4 text-right"
                            @click="showLess('last-reply', data.last_post[index].id, index)"
                          >
                            Show less
                          </div>
                        </div>
                      </v-card>
                      <v-divider class="mt-2 mb-2" />
                    </div>
                    <div
                      v-if="!isReply"
                      class="cursor-pointer text-caption"
                      @click="$refs.replyForm.show(th.id)"
                    >
                      <v-icon small>
                        {{ icons.mdiReply }}
                      </v-icon>
                      <span class="ml-4">Reply</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <thread-input
      ref="threadEdit"
      @update="$emit('success')"
    />
    <reply-input ref="replyForm" />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  mdiReply, mdiSquareEditOutline, mdiDelete, mdiDotsVertical,
  mdiArchiveArrowDown, mdiPin, mdiPinOff, mdiCommentQuestionOutline,
} from '@mdi/js'
import { avatarText } from '@/@core/utils/filter'
import { ref } from '@vue/composition-api'
import { createFieldMapper } from 'vuex-use-fields'
import ThreadInput from './components/ThreadInput.vue'
import ReplyInput from './components/ReplyInput.vue'
import { useDebounceFn } from '@vueuse/core'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { posts, threads } from '@/graphql/queries'
import {
  archiveThread, deletePost, deleteThread, pinThread, unpinThread,
} from '@/graphql/mutations'
import dateFormat from '@/utils/dateFormat'
import store from '@/store'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    ThreadInput,
    ReplyInput,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const state = {
      ...useFieldTeam(['activeChannel', 'threadListOffset', 'showPostOffset']),
    }
    const loadingFetchMore = ref(false)
    const isThreadMore = ref(false)
    const isReplyMore = ref(false)
    const isReply = ref(false)
    const isReadLastReply = ref(false)
    const isReadReply = ref(false)

    const scrollToBottom = () => {
      const el = document.getElementById('threadList')

      if (el) {
        el.scroll({ top: el.scrollHeight, behavior: 'smooth' })
      }
    }

    const listenScrollFetchMore = useDebounceFn(async data => {
      if (data.target.scrollTop < 10) {
        state.threadListOffset.value += 20

        loadingFetchMore.value = true
        apolloClient.query({
          query: threads,
          variables: {
            channel_id: state.activeChannel.value.channel.id,
            pagination: {
              limit: 20,
              offset: state.threadListOffset.value,
            },
            is_archived: false,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          loadingFetchMore.value = false
          if (result.data.threads.length > 0) {
            const last = result.data.threads.map(el => el.posts[el.posts.length - 1])
            state.activeChannel.value.threads = {
              thread: [
                result.data.threads.map(el => ({
                  ...el,
                  showMore: false,
                })),
                ...state.activeChannel.value.threads.thread,
              ],
              last_post: [
                last.length ? result.data.threads.map(el => ({
                  ...el.posts[el.posts.length - 1],
                  showReply: false,
                })) : undefined,
                ...state.activeChannel.value.threads.last_post,
              ],
              posts: [
                ...state.activeChannel.value.threads.posts,
              ],
              remainingOffset: [
                ...state.activeChannel.value.threads.remainingOffset,
              ],
            }
          }
        }).catch(err => {
          errorHandling(err)
          loadingFetchMore.value = false
        })
      }
    }, 200)

    const showMore = (name, id, index) => {
      if (name === 'thread') {
        props.data.thread.map(el => {
          // eslint-disable-next-line
          if (el.id === id) el.showMore = true

          return el
        })
      } else if (name === 'reply') {
        /* eslint-disable */
        props.data.posts = props.data.posts.map((el, x) => {
          if (x === index) {
            return el.map(e => {
              if (e.id === id) {
                e.showMore = true
              }

              return e
            })
          }

          return el
        })
        /* eslint-enable */
      } else if (name === 'last-reply') {
        // eslint-disable-next-line
        props.data.last_post[index].showMore = true
      }
    }
    const showLess = (name, id, index) => {
      if (name === 'thread') {
        props.data.thread.map(el => {
          // eslint-disable-next-line
          if (el.id === id) el.showMore = false

          return el
        })
      } else if (name === 'reply') {
        props.data.posts[index].map(el => {
          // eslint-disable-next-line
          if (el.id === id) el.showMore = false

          return el
        })
      } else if (name === 'last-reply') {
        // eslint-disable-next-line
        props.data.last_post[index].showMore = false
      }
    }

    const showRemaining = (id, x) => {
      if (!state.activeChannel.value.threads.remainingOffset[x]) {
        state.activeChannel.value.threads.remainingOffset[x] = 0
      }
      apolloClient.query({
        query: posts,
        variables: {
          thread_id: id,
          pagination: {
            limit: 10,
            offset: state.activeChannel.value.threads.remainingOffset[x],
          },
          timestamp: new Date(),
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        if (state.activeChannel.value.threads.remainingOffset[x] !== 0) {
          state.activeChannel.value.threads.posts[x] = [
            ...state.activeChannel.value.threads.posts[x],
            {
              ...result.data.posts,
              showMore: false,
            },
          ]
          state.activeChannel.value.threads.remainingOffset[x] += 10
        } else {
          state.activeChannel.value.threads.posts[x] = result.data.posts.filter(th => th.id !== state.activeChannel.value.threads.last_post[x].id).map(el => ({
            ...el,
            showMore: false,
          }))
        }
        state.activeChannel.value.threads.thread[x].remaining_post -= 10
      }).catch(err => {
        errorHandling(err)
      })
    }

    const showInput = str => {
      if (str === 'lastPost') {
        isReadLastReply.value = true
      } else if (str === 'post') {
        isReadReply.value = true
      }
    }

    const hapusThread = (id, x) => {
      Vue.$dialog({
        title: 'Hapus Thread',
        body: 'Apakah anda yakin ingin menghapus thread ini?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deleteThread,
            variables: {
              thread_id: id,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus thread!',
            })
            state.activeChannel.value.threads.thread = state.activeChannel.value.threads.thread.filter((th, index) => index !== x)
            state.activeChannel.value.threads.last_post = state.activeChannel.value.threads.last_post.filter((lp, index) => index !== x)
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    const archived = (id, x) => {
      apolloClient.mutate({
        mutation: archiveThread,
        variables: {
          thread_id: id,
        },
      }).then(() => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengarsipkan thread!',
        })
        state.activeChannel.value.threads.thread = state.activeChannel.value.threads.thread.filter((th, index) => index !== x)
        state.activeChannel.value.threads.last_post = state.activeChannel.value.threads.last_post.filter((lp, index) => index !== x)
      }).catch(err => {
        errorHandling(err)
      })
    }

    const setPin = (str, id, x) => {
      if (str === 'pin') {
        apolloClient.mutate({
          mutation: pinThread,
          variables: {
            thread_id: id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menandai thread!',
          })
          state.activeChannel.value.threads.thread[x].is_pinned = true
        }).catch(err => {
          errorHandling(err)
        })
      } else if (str === 'unpin') {
        apolloClient.mutate({
          mutation: unpinThread,
          variables: {
            thread_id: id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menghapus tanda thread!',
          })
          state.activeChannel.value.threads.thread[x].is_pinned = false
        }).catch(err => {
          errorHandling(err)
        })
      }
    }

    const hapusPost = (str, id, x) => {
      Vue.$dialog({
        title: 'Hapus Post',
        body: 'Apakah anda yakin ingin menghapus post ini?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deletePost,
            variables: {
              post_id: id,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus post!',
            })
            state.activeChannel.value.threads.posts[x] = state.activeChannel.value.threads.posts[x] ? state.activeChannel.value.threads.posts[x].filter(el => el.id !== id) : []
            state.activeChannel.value.threads.thread[x].posts = state.activeChannel.value.threads.thread[x].posts.filter(el => el.id !== id)
            if (str === 'last') {
              state.activeChannel.value.threads.last_post[x] = state.activeChannel.value.threads.thread[x].posts.length !== 0 ? state.activeChannel.value.threads.thread[x].posts.map((el, index) => {
                if (index === state.activeChannel.value.threads.thread[x].posts.length - 1) {
                  return {
                    ...el,
                    showMore: false,
                  }
                }

                return el
              })[state.activeChannel.value.threads.thread[x].posts.length - 1] : null
              state.activeChannel.value.threads.posts[x] = state.activeChannel.value.threads.posts[x]
                ? state.activeChannel.value.threads.posts[x].filter(el => el.id !== state.activeChannel.value.threads.last_post[x].id) : []
            }
            state.activeChannel.value.threads.thread[x].remaining_post -= 1
          }).catch(err => {
            console.log(err)
            errorHandling(err)
          })
        }
      })
    }

    return {
      isThreadMore,
      isReplyMore,
      isReply,
      isReadReply,
      loadingFetchMore,
      isReadLastReply,

      dateFormat,
      listenScrollFetchMore,
      scrollToBottom,
      showMore,
      showLess,
      hapusThread,
      archived,
      setPin,
      hapusPost,
      avatarText,
      showInput,
      showRemaining,
      state,
      store,
      icons: {
        mdiPin,
        mdiPinOff,
        mdiDotsVertical,
        mdiDelete,
        mdiReply,
        mdiSquareEditOutline,
        mdiArchiveArrowDown,
        mdiCommentQuestionOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import "~vue2-editor/dist/vue2-editor.css";

.menu-setting {
  visibility: hidden;
}

.html-card:hover .menu-setting{
  visibility: visible;
}

.full-width {
  width: calc((var(--vw, 1vw) * 50) - (1.5rem * 2));
}

.thread-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  max-height: 70px;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.thread-content-all {
  display: -webkit-box;
  height: max-content;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.reply-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 70px;
  width: calc((var(--vw, 1vw) * 45) - (1.5rem * 3));
  overflow: hidden;
}
.reply-content-all {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: max-content;
  width: calc((var(--vw, 1vw) * 45) - (1.5rem * 3));
  overflow: hidden;
}

.ps-thread {
  height: calc(100% - 75px);
  @include style-scroll-bar();
  overflow-y: scroll;
  overflow-x: hidden;
}

.ql-toolbar {
  background-color: white;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

pre.ql-syntax {
  background-color: #23241f;
  border-radius: 3px;
  color: #f8f8f2;
  overflow: visible;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

.content p {
  margin-top: 2px;
  margin-bottom: 2px;
}

</style>
