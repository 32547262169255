import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"text-h6 font-medium primary--text"},[_vm._v(" "+_vm._s(_vm.updateMode ? 'Edit Channel' : 'Channel Baru')+" ")]),_c(VIcon,{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,{staticClass:"pt-2"},[_c(VForm,{ref:"channelForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"rules":[_vm.required, _vm.maxLength(_vm.channelData.name, 50)],"label":"Nama (wajib diisi)","placeholder":"Input nama channel","dense":"","outlined":""},model:{value:(_vm.channelData.name),callback:function ($$v) {_vm.$set(_vm.channelData, "name", $$v)},expression:"channelData.name"}}),_c(VRow,{staticClass:"mt-0 align-content-center"},[_c(VCol,[_c(VCheckbox,{attrs:{"label":"Comment Permission"},model:{value:(_vm.channelData.comment_permission),callback:function ($$v) {_vm.$set(_vm.channelData, "comment_permission", $$v)},expression:"channelData.comment_permission"}})],1),_c(VCol,[_c(VCheckbox,{attrs:{"label":"Channel Notification"},model:{value:(_vm.channelData.channel_notification),callback:function ($$v) {_vm.$set(_vm.channelData, "channel_notification", $$v)},expression:"channelData.channel_notification"}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.reset}},[_vm._v(" Reset Form ")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingSubmit},on:{"click":_vm.submitForm}},[_vm._v(" Simpan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }