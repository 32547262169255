import gql from 'graphql-tag'

const addChannel = gql`
  mutation (
    $teamId: Float!
    $name: String!
    $comment_permission: Boolean!
    $channel_notification: Boolean!
    $is_pinned: Boolean!
  ) {
    addChannel (
      teamId: $teamId
      name: $name
      comment_permission: $comment_permission
      channel_notification: $channel_notification
      is_pinned: $is_pinned
    ) {
      status
      msg
      data
    }
  }
`

export default addChannel
