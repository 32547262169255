<template>
  <v-dialog
    v-model="showForm"
    max-width="600px"
    persistent
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">
          {{ updateMode ? 'Edit Channel' : 'Channel Baru' }}
        </span>
        <v-icon @click="close">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="channelForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="channelData.name"
            :rules="[required, maxLength(channelData.name, 50)]"
            label="Nama (wajib diisi)"
            placeholder="Input nama channel"
            dense
            outlined
          />
          <v-row class="mt-0 align-content-center">
            <v-col>
              <v-checkbox
                v-model="channelData.comment_permission"
                label="Comment Permission"
              />
            </v-col>
            <v-col>
              <v-checkbox
                v-model="channelData.channel_notification"
                label="Channel Notification"
              />
            </v-col>
          </v-row>
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="reset"
          >
            Reset Form
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingSubmit"
            @click="submitForm"
          >
            Simpan
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, maxLength } from '@core/utils/validation'
import { apolloClient } from '@/vue-apollo'
import { updateChannel } from '@/graphql/mutations'
import errorHandling from '@/utils/errorHandling'
import addChannel from '@/graphql/mutation/addChannel'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  setup() {
    const state = {
      ...useFieldTeam(['channelList']),
    }
    const teamId = ref(null)
    const showForm = ref(false)
    const updateMode = ref(false)
    const loadingSubmit = ref(false)
    const channelForm = ref(null)
    const channelData = ref({
      channel_id: null,
      name: null,
      comment_permission: false,
      channel_notification: false,
      is_pinned: false,
    })

    const resetValidation = () => channelForm.value.resetValidation()
    const show = id => {
      showForm.value = true
      teamId.value = id
    }
    const reset = () => {
      channelData.value = {
        channel_id: null,
        name: null,
        comment_permission: false,
        channel_notification: false,
        is_pinned: false,
      }
    }
    const close = () => {
      reset()
      showForm.value = false
    }
    const update = channel => {
      showForm.value = true
      updateMode.value = true
      channelData.value = {
        channel_id: channel.id,
        name: channel.name,
        comment_permission: channel.comment_permission,
        channel_notification: channel.channel_notification,
        is_pinned: channel.is_pinned,
      }
    }

    const submitForm = () => {
      if (channelForm.value.validate()) {
        loadingSubmit.value = true

        if (updateMode.value) {
          apolloClient.mutate({
            mutation: updateChannel,
            variables: {
              ...channelData.value,
            },
          }).then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah channel!',
            })
            const data = JSON.parse(result.data.updateChannel.data)
            /* eslint-disable */
            state.channelList.value.map(el => {
              if (el.id === data.id) {
                el.name = data.name
                el.comment_permission = data.comment_permission
                el.channel_notification = data.channel_notification
                el.is_pinned = data.is_pinned
                el.archived_at = data.archived_at
                el.deleted_at = data.deleted_at
              }

              return el
            })
            /* eslint-enable */
            loadingSubmit.value = false
            close()
          }).catch(err => {
            errorHandling(err)
            loadingSubmit.value = false
          })
        } else {
          apolloClient.mutate({
            mutation: addChannel,
            variables: {
              teamId: +teamId.value,
              name: channelData.value.name,
              comment_permission: channelData.value.comment_permission,
              channel_notification: channelData.value.channel_notification,
              is_pinned: channelData.value.is_pinned,
            },
          }).then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil membuat channel baru!',
            })
            state.channelList.value.push(JSON.parse(result.data.addChannel.data))
            loadingSubmit.value = false
            close()
          }).catch(err => {
            console.log(err)
            errorHandling(err)
            loadingSubmit.value = false
          })
        }
      }
    }

    return {
      showForm,
      channelForm,
      updateMode,
      channelData,
      loadingSubmit,
      required,
      maxLength,

      resetValidation,
      show,
      reset,
      close,
      update,
      submitForm,
      state,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss"></style>
