<template>
  <v-dialog
    v-model="showInput"
    max-width="60vw"
    scrollable
    persistent
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">
          {{ updateMode ? 'Edit Thread' : 'Thread Baru' }}
        </span>
        <v-icon @click="close">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <div class="pb-2 px-4">
        <vue-editor
          v-model="threadData.content"
          class="ql-blank"
          :editor-options="state.customSettingsQuill.value"
          use-custom-image-handler
          @image-added="handleImageAdded"
        />
      </div>
      <v-card-actions class="justify-end mt-4">
        <v-btn
          color="primary"
          :loading="loadingSubmit"
          @click="submit()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { VueEditor, Quill } from 'vue2-editor'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import ImageResize from 'quill-image-resize-vue'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import { addThread, updateThread, uploadPostPhoto } from '@/graphql/mutations'
import { createFieldMapper } from 'vuex-use-fields'

Quill.register('modules/imageResize', ImageResize)

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    VueEditor,
  },
  setup() {
    const state = {
      ...useFieldTeam(['activeChannel', 'customSettingsQuill']),
    }

    const showInput = ref(false)
    const updateMode = ref(false)
    const loadingSubmit = ref(false)
    const threadData = ref({
      threadId: null,
      content: null,
      isPinned: false,
    })
    const channelId = ref(null)

    const show = id => {
      showInput.value = true
      channelId.value = +id
    }
    const reset = () => {
      threadData.value = {
        threadId: null,
        content: null,
        isPinned: false,
      }
    }
    const close = () => {
      reset()
      showInput.value = false
    }
    const update = thread => {
      showInput.value = true
      updateMode.value = true
      channelId.value = +thread.channel.id
      threadData.value = {
        threadId: thread.id,
        content: thread.html_comment,
        isPinned: thread.is_pinned,
      }
    }
    const submit = () => {
      loadingSubmit.value = true
      if (updateMode.value) {
        apolloClient.mutate({
          mutation: updateThread,
          variables: {
            thread_id: threadData.value.threadId,
            html_comment: threadData.value.content,
            is_pinned: threadData.value.isPinned,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah thread!',
          })
          // emit('update')
          const data = JSON.parse(result.data.updateThread.data)
          state.activeChannel.value.threads.thread = state.activeChannel.value.threads.thread.map(th => {
            /* eslint-disable */
            if (th.id === data.id) {
              th.html_comment = data.html_comment
            }

            return th
            /* eslint-enable */
          })
          loadingSubmit.value = false
          close()
        }).catch(error => {
          errorHandling(error)
          loadingSubmit.value = false
        })
      } else {
        apolloClient.mutate({
          mutation: addThread,
          variables: {
            channelId: channelId.value,
            html_comment: threadData.value.content,
            is_pinned: threadData.value.isPinned,
          },
        }).then(result => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menambahkan thread!',
          })
          // emit('success')
          const data = JSON.parse(result.data.addThread.data)
          state.activeChannel.value.threads.thread.push({
            ...data,
            remaining_post: 0,
            posts: [],
          })
          state.activeChannel.value.threads.last_post.push(null)
          console.log(state.activeChannel.value.threads)
          loadingSubmit.value = false
          close()
        }).catch(error => {
          errorHandling(error)
          loadingSubmit.value = false
        })
      }
    }
    const handleImageAdded = (file, Editor, cursorLocation, resetUploader) => {
      console.log(file.size)
      if (file.size > 5000000) {
        Vue.notify({
          title: 'Gagal!',
          text: 'Ukuran file terlalu besar!',
        })
      } else {
        apolloClient.mutate({
          mutation: uploadPostPhoto,
          variables: {
            post_id: channelId.value,
            photo: file,
          },
        }).then(result => {
          const url = JSON.parse(result.data.uploadPostPhoto.data)
          console.log(url)
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        }).catch(err => {
          errorHandling(err)
        })
      }
    }

    return {
      show,
      reset,
      close,
      submit,
      update,
      handleImageAdded,
      state,
      loadingSubmit,
      updateMode,
      threadData,
      showInput,
      icons: {
        mdiClose,
      },
    }
  },

}
</script>

<style lang="scss">
@import "~vue2-editor/dist/vue2-editor.css";

.ql-toolbar {
  background-color: white;
}

</style>
