<template>
  <v-card class="max-h-content-container app-chat position-relative overflow-hidden text-sm">
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="320"
      mobile-breakpoint="sm"
      :temporary="$vuetify.breakpoint.xsOnly"
      touchless
      absolute
      class="left-sidebar"
    >
      <channel-sidebar
        :team-id="teamId"
        :loading="loadingChannels"
        @close-left-sidebar="isLeftSidebarOpen = false"
        @open-channel="$router.replace({ name: 'channel', params: { id: teamId, channelid: $event.id } }); openChannel($event.id)"
        @onScrollChannel="listenScrollFetchMoreChannel"
        @refetch="fetchChannelList"
      />
    </v-navigation-drawer>
    <div class="d-flex h-full">
      <div
        class="chat-content-area h-full d-flex flex-column w-100"
        :style="{
          'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '320px' : null,
          'margin-right': isRightSidebarOpen && $vuetify.breakpoint.mdAndUp ? '320px' : null,
        }"
      >
        <div
          v-if="state.activeChannel.value"
          class="h-full"
        >
          <div
            class="d-flex align-center px-5 py-4"
            style="height: 66px;"
          >
            <div class="d-flex align-center">
              <v-btn
                icon
                class="me-2 d-inline-block d-md-none"
              >
                <v-icon @click="isLeftSidebarOpen = true">
                  {{ icons.mdiMenu }}
                </v-icon>
              </v-btn>
            </div>
            <div class="d-flex">
              <v-icon dense>
                {{ icons.mdiAt }}
              </v-icon>
              <span class="ml-4 text-subtitle-1">{{ state.activeChannel.value.channel.name }}</span>
            </div>
            <div
              class="ms-auto d-flex align-center"
            >
              <v-menu
                offset-y
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="warning"
                    outlined
                    icon
                    small
                    class="mr-2 ms-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchPinnedThreads(+router.currentRoute.params.channelid)"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiPin }}
                    </v-icon>
                  </v-btn>
                </template>

                <pin-card />
              </v-menu>
              <v-menu
                offset-y
                left
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    outlined
                    icon
                    small
                    class="mr-2 ms-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchArchivedThreads(+router.currentRoute.params.channelid);"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiArchive }}
                    </v-icon>
                  </v-btn>
                </template>

                <archived-card
                  @success="openChannel(+router.currentRoute.params.channelid)"
                />
              </v-menu>
              <v-btn
                color="primary"
                outlined
                icon
                small
                class="mr-2 ms-2"
                @click="$refs.threadInput.show(state.activeChannel.value.channel.id)"
              >
                <v-icon
                  small
                >
                  {{ icons.mdiChatPlus }}
                </v-icon>
              </v-btn>
              <v-menu
                :close-on-content-click="false"
                :close-on-click="state.closeMenuSearch.value"
                offset-y
                content-class="menu-content"
                left
                min-width="360px"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    icon
                    small
                    class="mr-2 ms-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      small
                    >
                      {{ icons.mdiFeatureSearch }}
                    </v-icon>
                  </v-btn>
                </template>

                <search-thread ref="searchThreads" />
              </v-menu>
            </div>
          </div>
          <v-divider />

          <thread-list
            ref="refThreadList"
            :loading="loadingActiveChannel"
            :data="state.activeChannel.value.threads"
            @success="openChannel(+router.currentRoute.params.channelid)"
          />
        </div>
        <!-- No Channel Selected -->
        <div
          v-else
          class="h-full"
        >
          <div
            v-if="!isLeftSidebarOpen"
            class="d-flex align-center justify-space-between px-5 py-4"
            style="height: 70px"
          >
            <div class="d-flex align-center">
              <v-btn
                icon
                class="me-2 d-inline-block d-md-none"
              >
                <v-icon @click="isLeftSidebarOpen = true">
                  {{ icons.mdiMenu }}
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-center h-full flex-grow-1 flex-column">
            <v-avatar
              size="109"
              class="elevation-3 mb-6 bg-card"
            >
              <v-icon
                size="50"
                class="rounded-0 text--primary"
              >
                {{ icons.mdiForumOutline }}
              </v-icon>
            </v-avatar>
            <p
              class="mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card"
              :class="[{ 'cursor-pointer': $vuetify.breakpoint.smAndDown }]"
            >
              Forum Channel Oriens CRM
            </p>
          </div>
        </div>
        <thread-input
          ref="threadInput"
          @success="openChannel(+router.currentRoute.params.channelid)"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { createFieldMapper } from 'vuex-use-fields'
import ThreadInput from './components/ThreadInput.vue'
import ChannelSidebar from './ChannelSidebar.vue'
import ThreadList from './ThreadList.vue'
import PinCard from './components/PinCard.vue'
import ArchivedCard from './components/ArchivedCard.vue'
import SearchThread from './components/SearchThread.vue'
import useVuetify from '@core/utils/vuetify'
import { ref, onMounted, nextTick } from '@vue/composition-api'
import {
  mdiClose, mdiForumOutline, mdiMenu, mdiAt, mdiChatPlus, mdiArchive, mdiPin, mdiFeatureSearch,
} from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { channels, threads } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import router from '@/router'
import { useDebounceFn } from '@vueuse/core'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    PinCard,
    ArchivedCard,
    ThreadInput,
    ChannelSidebar,
    ThreadList,
    SearchThread,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const state = {
      ...useFieldTeam(['loadingChannels', 'channelList', 'activeChannel', 'channelFilter',
        'threadListOffset', 'archivedChannel', 'pinnedThreadsList', 'archivedThreadsList', 'closeMenuSearch']),
    }
    const teamId = props.id
    const loadingActiveChannel = ref(false)
    const loadingChannels = ref(false)
    const refThreadList = ref()
    const searchThreads = ref()
    const { rootThemeClasses } = useVuetify()
    const { isLeftSidebarOpen, isRightSidebarOpen } = useResponsiveLeftSidebar({ sidebarWidth: 320 })
    const fetchChannelList = async (fetchMore = false, loading = true, success = () => {}) => {
      const filter = state.channelFilter.value
      if (loading) state.loadingChannels.value = true
      await apolloClient.query({
        query: channels,
        variables: {
          team_id: +teamId,
          pagination: {
            limit: 20,
            offset: filter.channelOffset,
          },
          filter: {
            sortType: filter.sort.field,
            sort: filter.sort.type,
          },
          is_archived: state.channelFilter.value.isArchived,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        if (fetchMore) {
          state.channelList.value = [...state.channelList.value, ...result.data.channels]
        } else {
          state.channelList.value = result.data.channels
        }
        success()
        state.loadingChannels.value = false
      })
    }

    const fetchArchivedThreads = async channelId => {
      await apolloClient.query({
        query: threads,
        variables: {
          channel_id: channelId,
          pagination: {
            limit: 10,
            offset: 0,
          },
          is_archived: true,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.archivedThreadsList.value = result.data.threads
      })
    }

    const fetchPinnedThreads = async channelId => {
      await apolloClient.query({
        query: threads,
        variables: {
          channel_id: channelId,
          pagination: {
            limit: 10,
            offset: 0,
          },
          is_pinned: true,
          is_archived: false,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        state.pinnedThreadsList.value = result.data.threads
      })
    }

    const listenScrollFetchMoreChannel = useDebounceFn(async data => {
      if ((data.target.offsetHeight + data.target.scrollTop) >= (data.target.scrollHeight - 100)) {
        state.channelFilter.value.channelOffset += 20
        loadingChannels.value = true
        await fetchChannelList(true, false, () => {
          loadingChannels.value = false
        })
      }
    }, 1000)

    const openChannel = async (channelId, loading = true) => {
      if (state.closeMenuSearch.value === false) searchThreads.value.resetSearch()
      const unarchived = state.channelList.value.find(el => el.id === channelId)
      const archived = state.archivedChannel.value.find(el => el.id === channelId)

      const data = unarchived || archived
      state.threadListOffset.value = 0

      if (data) {
        if (loading) loadingActiveChannel.value = true
        apolloClient.query({
          query: threads,
          variables: {
            channel_id: channelId,
            pagination: {
              limit: 20,
              offset: 0,
            },
            is_archived: false,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          fetchArchivedThreads(channelId)
          fetchPinnedThreads(channelId)
          state.activeChannel.value = {
            threads: {
              thread: result.data.threads.sort((a, b) => new Date(Date.parse(a.last_activity_at)) - new Date(Date.parse(b.last_activity_at))).map(el => ({
                ...el,
                showMore: false,
              })),
              last_post: result.data.threads.map(el => ({
                ...el.posts[el.posts.length - 1],
                showMore: false,
              })),
              posts: [],
              remainingOffset: [],
            },
            channel: {
              id: data.id,
              name: data.name,
            },
          }
          setTimeout(() => {
            loadingActiveChannel.value = false

            nextTick(() => {
              refThreadList.value.scrollToBottom()
            })
          }, 1000)
        }).catch(err => {
          errorHandling(err)
          loadingActiveChannel.value = false
        })
      }
    }

    onMounted(async () => {
      if (!state.channelList.value.length) {
        state.channelFilter.value.channelOffset = 0
        await fetchChannelList()
      }

      if (router.currentRoute.params && router.currentRoute.params.channelid) {
        openChannel(+router.currentRoute.params.channelid)
        state.threadListOffset.value = 0
      }
    })

    return {
      isLeftSidebarOpen,
      isRightSidebarOpen,
      rootThemeClasses,
      state,
      loadingActiveChannel,
      loadingChannels,
      teamId,
      router,
      fetchChannelList,
      fetchArchivedThreads,
      fetchPinnedThreads,
      listenScrollFetchMoreChannel,
      openChannel,
      refThreadList,
      searchThreads,

      icons: {
        mdiClose,
        mdiForumOutline,
        mdiMenu,
        mdiAt,
        mdiPin,
        mdiChatPlus,
        mdiArchive,
        mdiFeatureSearch,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';

.left-sidebar .v-navigation-drawer__content {
  overflow-y: hidden;
}

.menu-content {
  margin-top: 1.6em;
}
</style>
