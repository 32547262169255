<template>
  <v-card
    :loading="loading"
  >
    <div class="pr-2 pt-4 pl-4 pb-5 align-items-center">
      <v-text-field
        v-if="state.closeMenuSearch.value"
        v-model="search"
        placeholder="search..."
        class="mt-0 pt-0"
        :height="iconSize"
        :clear-icon="icons.mdiClose"
        :append-icon="icons.mdiMagnify"
        hide-details
        dense
        clearable
        @click:append="searchText"
        @click:clear="resetSearch"
      />
      <div
        v-else
        class="mt-0 pt-0 d-flex align-center"
      >
        <div class="d-flex justify-space-between flex-grow-1">
          <div><small>Keyword : <b>{{ search }}</b></small></div>
          <div><small><b>{{ indexHighlight + 1 }}</b> dari <b>{{ matchesCount }}</b></small></div>
        </div>
        <v-btn
          icon
          class="ml-auto mr-1"
          @click="resetSearch"
        >
          <v-icon :size="iconSize">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
        <div class="d-flex">
          <v-btn
            class="flex-grow-1"
            icon
            :disabled="indexHighlight === 0 || matchesCount === 0"
            @click="searchUp"
          >
            <v-icon :size="iconSize">
              {{ icons.mdiChevronUp }}
            </v-icon>
          </v-btn>
          <v-btn
            class="flex-grow-1"
            icon
            :disabled="indexHighlight + 1 === matchesCount"
            @click="searchDown"
          >
            <v-icon :size="iconSize">
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import {
  mdiClose, mdiMagnify, mdiChevronUp, mdiChevronDown,
} from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { threadSearch } from '@/graphql/queries'
import router from '@/router'
import errorHandling from '@/utils/errorHandling'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  setup() {
    const state = {
      ...useFieldTeam(['closeMenuSearch']),
    }
    const iconSize = ref(18)
    const search = ref('')
    const searchData = ref([])
    const indexHighlight = ref(0)
    const matchesCount = ref(0)
    const loading = ref(false)
    // const channelId = +router.currentRoute.params.channelid

    const searchText = async () => {
      if (search.value.length >= 3) {
        loading.value = true
        await apolloClient.query({
          query: threadSearch,
          variables: {
            search: search.value,
            channel_id: +router.currentRoute.params.channelid,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          state.closeMenuSearch.value = false
          loading.value = false
          matchesCount.value = result.data.threadSearch.count
          searchData.value = result.data.threadSearch
        }).catch(err => {
          loading.value = false
          errorHandling(err)
        })
        indexHighlight.value = matchesCount.value - 1
      } else {
        Vue.notify({
          type: 'error',
          title: 'Error',
          text: 'Search keyword must be at least 3 characters',
        })
      }
    }

    const searchUp = async () => {
      const indexNow = indexHighlight.value
      if (indexNow - 1 >= 0) {
        indexHighlight.value -= 1
      }
    }

    const searchDown = async () => {
      const indexNow = indexHighlight.value
      if (indexNow + 1 < matchesCount.value) {
        indexHighlight.value += 1
      }
    }

    const resetSearch = () => {
      state.closeMenuSearch.value = true
      search.value = ''
      searchData.value = []
      indexHighlight.value = 0
      matchesCount.value = 0
    }

    return {
      iconSize,
      search,
      searchData,
      indexHighlight,
      matchesCount,
      loading,
      // channelId,
      state,

      searchText,
      searchUp,
      searchDown,
      resetSearch,

      icons: {
        mdiClose,
        mdiMagnify,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>

<style>

</style>
