<template>
  <div
    class="mx-3 my-3 pin-card"
  >
    <div v-if="state.pinnedThreadsList.value.length !== 0">
      <v-card
        v-for="dt in state.pinnedThreadsList.value"
        :key="dt.id"
        outlined
        class="chat-group d-flex align-start px-2 py-2 mb-2"
      >
        <div class="chat-avatar">
          <router-link
            class="d-flex"
            :to="{ name: 'user-detail', params: { id: dt.user.id } }"
          >
            <v-avatar
              size="38"
              color="primary"
            >
              <span class="white--text">{{ avatarText(dt.user.name) }}</span>
            </v-avatar>
          </router-link>
        </div>
        <div class="flex-column ml-4 mb-4 html-card">
          <div class="d-flex">
            <div class="d-flex flex-grow-1">
              <router-link
                class="mr-1"
                :to="{ name: 'user-detail', params: { id: dt.user.id} }"
              >
                <p class="text-caption">
                  {{ dt.user.name }}
                </p>
              </router-link>
              <span class="text-caption">| {{ dateFormat(dt.created_at) }}</span>
            </div>
            <div class="mr-2 flex-row">
              <v-btn
                class="menu-setting"
                elevation="1"
                x-small
                plain
                @click="jumpTo(dt.id)"
              >
                <strong><em>jump</em></strong>
              </v-btn>
            </div>
          </div>
          <div class="d-flex">
            <div class="pin-width">
              <v-card
                class="py-3 px-4 elevation-1"
              >
                <div class="d-flex">
                  <div
                    class="flex-grow-1"
                  >
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                      class="content"
                      v-html="dt.html_comment"
                    />
                    <!-- eslint enable -->
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <v-card
      v-else
      class="text-center px-6 py-6"
    >
      <v-icon
        class="mb-2"
        size="64px"
      >
        {{ icons.mdiPinOff }}
      </v-icon>
      <div class="text-h6">
        No Pinned Threads
      </div>
      <div class="text-body-2">
        Pinned threads will appear here.
      </div>
    </v-card>
  </div>
</template>

<script>
import { mdiPinOff } from '@mdi/js'
import { avatarText } from '@/@core/utils/filter'
import { createFieldMapper } from 'vuex-use-fields'
import dateFormat from '@/utils/dateFormat'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
  },
  setup() {
    const state = {
      ...useFieldTeam(['pinnedThreadsList']),
    }
    const jumpTo = id => {
      document.getElementById(`threadContent-${id}`).scrollIntoView({ behavior: 'smooth' })
    }

    return {
      avatarText,
      dateFormat,
      jumpTo,
      state,
      icons: {
        mdiPinOff,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.pin-card {
  min-width: 320px;
}
.pin-width {
  width: calc((var(--vw, 1vw) * 30) - (1.5rem * 2));
}
</style>
