<template>
  <div
    class="chat-contact rounded-lg pa-3 cursor-pointer"
    :class="{ 'bg-gradient-info': isActive }"
    v-on="$listeners"
  >
    <div class="d-flex align-center channel-card">
      <div class="flex-grow-1 overflow-hidden">
        <span
          :class="{ 'text-white': isActive }"
          class="mr-auto"
        >{{ data.name }}</span>
      </div>
      <v-chip
        v-if="data.archived_at !== null"
        small
        class="mr-2"
        :text-color="isActive ? 'white' : ''"
      >
        <em>archived</em>
      </v-chip>
      <div class="flex-column">
        <v-menu
          bottom
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :class="[{ 'text-white': isActive }]"
              class="channel-setting"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                size="18px"
              >
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>
          <v-list
            nav
          >
            <v-list-item
              v-show="!data.is_pinned"
              @click="setPin('pin')"
            >
              <v-icon
                small
              >
                {{ icons.mdiPin }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Pin</span>
            </v-list-item>
            <v-list-item
              v-show="data.is_pinned"
              @click="setPin('unpin')"
            >
              <v-icon
                small
              >
                {{ icons.mdiPinOff }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Unpin</span>
            </v-list-item>
            <v-list-item
              v-show="data.archived_at === null"
              @click="archived('archive')"
            >
              <v-icon
                small
              >
                {{ icons.mdiArchiveArrowDown }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Archive</span>
            </v-list-item>
            <v-list-item
              v-show="data.archived_at !== null"
              @click="archived('unarchive')"
            >
              <v-icon
                small
              >
                {{ icons.mdiArchiveArrowUp }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Unarchive</span>
            </v-list-item>
            <v-divider />
            <v-list-item @click="$emit('update')">
              <v-icon
                small
              >
                {{ icons.mdiSquareEditOutline }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Edit</span>
            </v-list-item>
            <v-list-item @click="deleted()">
              <v-icon
                small
              >
                {{ icons.mdiDelete }}
              </v-icon>
              <span class="ml-4 text-subtitle-2">Hapus</span>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-icon
          v-show="data.is_pinned"
          small
          class="ml-2"
          :class="[{ 'text-white': isActive }]"
        >
          {{ icons.mdiPin }}
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  archiveChannel, deleteChannel, pinChannel, unarchiveChannel, unpinChannel,
} from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'
import {
  mdiPin, mdiDotsVertical, mdiSquareEditOutline,
  mdiPinOff, mdiDelete, mdiArchiveArrowDown, mdiArchiveArrowUp,
  mdiArchive,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import errorHandling from '@/utils/errorHandling'
import router from '@/router'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  props: {
    team: {
      type: [String, Number],
      default: null,
    },
    data: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = {
      ...useFieldTeam(['activeChannel', 'channelList', 'archivedChannel']),
    }

    const isArchived = ref(false)
    const setPin = str => {
      if (str === 'pin') {
        apolloClient.mutate({
          mutation: pinChannel,
          variables: {
            channel_id: props.data.id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil pin channel',
          })
          emit('success')
        }).catch(err => {
          errorHandling(err)
        })
      } else {
        apolloClient.mutate({
          mutation: unpinChannel,
          variables: {
            channel_id: props.data.id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil unpin channel',
          })
          emit('success')
        }).catch(err => {
          errorHandling(err)
        })
      }
    }
    const archived = str => {
      if (str === 'archive') {
        apolloClient.mutate({
          mutation: archiveChannel,
          variables: {
            channel_id: props.data.id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil archive channel',
          })
          state.channelList.value = state.channelList.value.filter(item => item.id !== props.data.id)
        }).catch(err => {
          errorHandling(err)
        })
      } else {
        apolloClient.mutate({
          mutation: unarchiveChannel,
          variables: {
            channel_id: props.data.id,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses',
            text: 'Berhasil unarchive channel',
          })
          state.archivedChannel.value = state.archivedChannel.value.filter(item => item.id !== props.data.id)
        }).catch(err => {
          errorHandling(err)
        })
      }
    }
    const deleted = () => {
      Vue.$dialog({
        title: 'Hapus Channel',
        body: 'Apakah anda yakin ingin menghapus channel ini?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: deleteChannel,
            variables: {
              channel_id: props.data.id,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil delete channel',
            })
            if (router.currentRoute.params.channelid === props.data.id) {
              router.replace({ name: 'team-detail', params: { id: props.team } })
              state.activeChannel.value = null
            }
            state.channelList.value = state.channelList.value.filter(item => item.id !== props.data.id)
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }

    return {
      isArchived,
      deleted,
      setPin,
      archived,
      state,

      icons: {
        mdiPin,
        mdiDotsVertical,
        mdiSquareEditOutline,
        mdiPinOff,
        mdiDelete,
        mdiArchive,
        mdiArchiveArrowDown,
        mdiArchiveArrowUp,
      },
    }
  },
}
</script>

<style lang="scss">
.channel-setting {
  visibility: hidden;
}
.channel-card:hover .channel-setting{
  visibility: visible;
}
</style>
