<template>
  <v-dialog
    v-model="showInput"
    max-width="60vw"
    scrollable
    persistent
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">
          {{ updateMode ? 'Edit Reply' : 'Reply Baru' }}
        </span>
        <v-icon @click="close">
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <div class="pb-2 px-4">
        <vue-editor
          v-model="replyData.content"
          class="ql-blank"
          :editor-options="state.customSettingsQuill.value"
          use-custom-image-handler
          @image-added="handleImageAdded"
        />
      </div>
      <v-card-actions class="justify-end mt-4">
        <v-btn
          color="primary"
          :loading="loadingSubmit"
          @click="submit()"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { VueEditor, Quill } from 'vue2-editor'
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import ImageResize from 'quill-image-resize-vue'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import { addPost, updatePost, uploadPostPhoto } from '@/graphql/mutations'
import { createFieldMapper } from 'vuex-use-fields'

Quill.register('modules/imageResize', ImageResize)

const useFieldTeam = createFieldMapper({ getter: 'team/getField', setter: 'team/setField' })

export default {
  components: {
    VueEditor,
  },
  setup() {
    const state = {
      ...useFieldTeam(['activeChannel', 'customSettingsQuill']),
    }

    const showInput = ref(false)
    const updateMode = ref(false)
    const loadingSubmit = ref(false)
    const replyData = ref({
      postId: null,
      content: null,
    })
    const threadId = ref(null)
    const indx = ref(null)
    const tipe = ref(null)

    const show = id => {
      showInput.value = true
      threadId.value = +id
    }
    const reset = () => {
      threadId.value = null
      updateMode.value = false
      indx.value = null
      tipe.value = null
      replyData.value = {
        postId: null,
        content: null,
      }
    }
    const close = () => {
      reset()
      showInput.value = false
    }
    const update = (str, post, x, th) => {
      showInput.value = true
      updateMode.value = true
      threadId.value = +th
      indx.value = +x
      tipe.value = str
      replyData.value = {
        postId: post.id,
        content: post.html_comment,
      }
    }
    const submit = () => {
      loadingSubmit.value = true
      if (updateMode.value) {
        apolloClient
          .mutate({
            mutation: updatePost,
            variables: {
              post_id: replyData.value.postId,
              html_comment: replyData.value.content,
            },
          })
          .then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengubah reply!',
            })
            const data = JSON.parse(result.data.updatePost.data)
            if (tipe.value === 'last') {
              state.activeChannel.value.threads.last_post[indx.value].html_comment = data.html_comment
            }
            state.activeChannel.value.threads.posts = state.activeChannel.value.threads.posts.map((el, x) => {
              /* eslint-disable */
              if (x === indx.value) {
                el.map(post => {
                  if (post.id === replyData.value.postId) {
                    post.html_comment = data.html_comment
                  }

                  return post
                })
              }

              return el
              /* eslint-enable */
            })
            // }

            loadingSubmit.value = false
            close()
          })
          .catch(error => {
            console.log(error)
            errorHandling(error)
            loadingSubmit.value = false
          })
      } else {
        apolloClient
          .mutate({
            mutation: addPost,
            variables: {
              threadId: threadId.value,
              html_comment: replyData.value.content,
            },
          })
          .then(result => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menambahkan reply!',
            })
            const data = JSON.parse(result.data.addPost.data)
            state.activeChannel.value.threads.thread.map((el, index) => {
              if (el.id === threadId.value) {
                /* eslint-disable */
                el.remaining_post = state.activeChannel.value.threads.thread[index].posts.length >= 1 ? el.remaining_post + 1 : 0
                if (state.activeChannel.value.threads.posts[index] !== undefined && state.activeChannel.value.threads.posts[index].length > 0) {
                  state.activeChannel.value.threads.posts[index].push(state.activeChannel.value.threads.last_post[index])
                }
                state.activeChannel.value.threads.last_post = state.activeChannel.value.threads.last_post.map((el, x) => {
                  if (x === index) {
                    el = {
                      ...data,
                      showMore: false,
                    }
                  }

                  return el
                })
                state.activeChannel.value.threads.thread[index].posts.push(state.activeChannel.value.threads.last_post[index])
              /* eslint-enable */
              }

              return el
            })
            loadingSubmit.value = false
            close()
          })
          .catch(error => {
            console.log(error)
            errorHandling(error)
            loadingSubmit.value = false
          })
      }
    }
    const handleImageAdded = (file, Editor, cursorLocation, resetUploader) => {
      console.log(file.size)
      if (file.size > 5000000) {
        Vue.notify({
          title: 'Gagal!',
          text: 'Ukuran file terlalu besar!',
        })
      } else {
        apolloClient
          .mutate({
            mutation: uploadPostPhoto,
            variables: {
              post_id: threadId.value,
              photo: file,
            },
          })
          .then(result => {
            const url = JSON.parse(result.data.uploadPostPhoto.data)
            console.log(url)
            Editor.insertEmbed(cursorLocation, 'image', url)
            resetUploader()
          })
          .catch(err => {
            errorHandling(err)
          })
      }
    }

    return {
      show,
      reset,
      close,
      submit,
      update,
      handleImageAdded,
      state,
      loadingSubmit,
      updateMode,
      replyData,
      showInput,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
.ql-toolbar {
  background-color: white;
}
</style>
