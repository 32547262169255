import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"max-h-content-container app-chat position-relative overflow-hidden text-sm"},[_c(VNavigationDrawer,{staticClass:"left-sidebar",attrs:{"width":"320","mobile-breakpoint":"sm","temporary":_vm.$vuetify.breakpoint.xsOnly,"touchless":"","absolute":""},model:{value:(_vm.isLeftSidebarOpen),callback:function ($$v) {_vm.isLeftSidebarOpen=$$v},expression:"isLeftSidebarOpen"}},[_c('channel-sidebar',{attrs:{"team-id":_vm.teamId,"loading":_vm.loadingChannels},on:{"close-left-sidebar":function($event){_vm.isLeftSidebarOpen = false},"open-channel":function($event){_vm.$router.replace({ name: 'channel', params: { id: _vm.teamId, channelid: $event.id } }); _vm.openChannel($event.id)},"onScrollChannel":_vm.listenScrollFetchMoreChannel,"refetch":_vm.fetchChannelList}})],1),_c('div',{staticClass:"d-flex h-full"},[_c('div',{staticClass:"chat-content-area h-full d-flex flex-column w-100",style:({
        'margin-left': _vm.isLeftSidebarOpen && _vm.$vuetify.breakpoint.mdAndUp ? '320px' : null,
        'margin-right': _vm.isRightSidebarOpen && _vm.$vuetify.breakpoint.mdAndUp ? '320px' : null,
      })},[(_vm.state.activeChannel.value)?_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"d-flex align-center px-5 py-4",staticStyle:{"height":"66px"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VBtn,{staticClass:"me-2 d-inline-block d-md-none",attrs:{"icon":""}},[_c(VIcon,{on:{"click":function($event){_vm.isLeftSidebarOpen = true}}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")])],1)],1),_c('div',{staticClass:"d-flex"},[_c(VIcon,{attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAt)+" ")]),_c('span',{staticClass:"ml-4 text-subtitle-1"},[_vm._v(_vm._s(_vm.state.activeChannel.value.channel.name))])],1),_c('div',{staticClass:"ms-auto d-flex align-center"},[_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 ms-2",attrs:{"color":"warning","outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.fetchPinnedThreads(+_vm.router.currentRoute.params.channelid)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPin)+" ")])],1)]}}],null,false,2996487738)},[_c('pin-card')],1),_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 ms-2",attrs:{"color":"error","outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.fetchArchivedThreads(+_vm.router.currentRoute.params.channelid);}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiArchive)+" ")])],1)]}}],null,false,1375876184)},[_c('archived-card',{on:{"success":function($event){return _vm.openChannel(+_vm.router.currentRoute.params.channelid)}}})],1),_c(VBtn,{staticClass:"mr-2 ms-2",attrs:{"color":"primary","outlined":"","icon":"","small":""},on:{"click":function($event){return _vm.$refs.threadInput.show(_vm.state.activeChannel.value.channel.id)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiChatPlus)+" ")])],1),_c(VMenu,{attrs:{"close-on-content-click":false,"close-on-click":_vm.state.closeMenuSearch.value,"offset-y":"","content-class":"menu-content","left":"","min-width":"360px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2 ms-2",attrs:{"color":"primary","outlined":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFeatureSearch)+" ")])],1)]}}],null,false,1023455783)},[_c('search-thread',{ref:"searchThreads"})],1)],1)]),_c(VDivider),_c('thread-list',{ref:"refThreadList",attrs:{"loading":_vm.loadingActiveChannel,"data":_vm.state.activeChannel.value.threads},on:{"success":function($event){return _vm.openChannel(+_vm.router.currentRoute.params.channelid)}}})],1):_c('div',{staticClass:"h-full"},[(!_vm.isLeftSidebarOpen)?_c('div',{staticClass:"d-flex align-center justify-space-between px-5 py-4",staticStyle:{"height":"70px"}},[_c('div',{staticClass:"d-flex align-center"},[_c(VBtn,{staticClass:"me-2 d-inline-block d-md-none",attrs:{"icon":""}},[_c(VIcon,{on:{"click":function($event){_vm.isLeftSidebarOpen = true}}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")])],1)],1)]):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-center h-full flex-grow-1 flex-column"},[_c(VAvatar,{staticClass:"elevation-3 mb-6 bg-card",attrs:{"size":"109"}},[_c(VIcon,{staticClass:"rounded-0 text--primary",attrs:{"size":"50"}},[_vm._v(" "+_vm._s(_vm.icons.mdiForumOutline)+" ")])],1),_c('p',{staticClass:"mb-0 px-6 py-1 font-weight-medium text-lg elevation-3 rounded-xl text--primary bg-card",class:[{ 'cursor-pointer': _vm.$vuetify.breakpoint.smAndDown }]},[_vm._v(" Forum Channel Oriens CRM ")])],1)]),_c('thread-input',{ref:"threadInput",on:{"success":function($event){return _vm.openChannel(+_vm.router.currentRoute.params.channelid)}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }